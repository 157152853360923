var $ = require( "jquery" )

require("slick-carousel")

import "slick-carousel/slick/slick.scss"

// Initialize sliders function
function initializeSliders() {
  // Hero Slider
  if ($(".hero-slider").length && !$(".hero-slider").hasClass('slick-initialized')) {
    $(".hero-slider").slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 1000,
      cssEase: "linear",
      prevArrow: "<button type='button' class='slick-prev slick-arrow'><i class='fal fa-chevron-left'></i></button>",
      nextArrow: "<button type='button' class='slick-next slick-arrow'><i class='fal fa-chevron-right'></i></button>",
      responsive: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
              arrows: false,
          },
        },
      ],
      lazyLoad: 'ondemand',
      lazyLoadBuffer: 1000,
    });
  }

  // Fresh Slider
  if ($(".fresh-slider").length && !$(".fresh-slider").hasClass('slick-initialized')) {
    $(".fresh-slider").slick({
        infinite: true,
        slidesToShow: 3,
        prevArrow: "<button type='button' class='slick-prev slick-arrow'><i class='fal fa-chevron-left'></i></button>",
        nextArrow: "<button type='button' class='slick-next slick-arrow'><i class='fal fa-chevron-right'></i></button>",
        responsive: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    centerMode: true,
                    arrows: false,
                    centerPadding: "210px 0 0",
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 447,
                settings: {
                    centerMode: true,
                    arrows: false,
                    centerPadding: "150px 0 0",
                    slidesToShow: 1,
                },
            },
        ],
    });
  }

  // Products Slider
  if ($(".products-slider").length && !$(".products-slider").hasClass('slick-initialized')) {
    $(".products-slider").slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: "<button type='button' class='slick-prev slick-arrow'><i class='far fa-chevron-left'></i></button>",
        nextArrow: "<button type='button' class='slick-next slick-arrow'><i class='far fa-chevron-right'></i></button>",
        responsive: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    centerMode: true,
                    arrows: false,
                    centerPadding: "210px 0 0",
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 447,
                settings: {
                    centerMode: true,
                    arrows: false,
                    centerPadding: "150px 0 0",
                    slidesToShow: 1,
                },
            },
        ],
    });
  }
}

document.addEventListener("turbolinks:load", function() {
  // Initialize sliders
  initializeSliders();

  // Quantity Inputs
  $(".quantity").on("click", ".plus", function (e) {
      let $input = $(this).prev("input.qty");
      let val = parseInt($input.val());
      $input.val(val + 1).change();
  });

  $(".quantity").on("click", ".minus", function (e) {
      let $input = $(this).next("input.qty");
      var val = parseInt($input.val());
      if (val > 1) {
          $input.val(val - 1).change();
      }
  });

  // Fix Header
  $(window).scroll(function () {
      if ($(this).scrollTop() > 218) {
          $("header").addClass("sticky");
      } else {
          $("header").removeClass("sticky");
      }
  });

  // Fix Top Head
  $(window).scroll(function () {
      if ($(this).scrollTop() > 164) {
          $(".top-head").addClass("top");
      } else {
          $(".top-head").removeClass("top");
      }
  });

  // Accordion
  document.querySelectorAll(".accordion-header").forEach((header) => {
      header.addEventListener("click", () => {
          const content = header.nextElementSibling;
          content.classList.toggle("show");
      });
  });

  // Product Gallery
  function initializeProductGallery() {
      const gallery = document.getElementById('product-gallery');
      if (!gallery) return;

      const variantRadios = document.querySelectorAll('.product-variants-variant-values-radio');
      const productImages = gallery.querySelectorAll('.product-image');

      function updateGalleryImage(variantId) {
          let imageFound = false;
          productImages.forEach(image => {
              if (image.dataset.variantId === variantId) {
                  image.classList.remove('hidden');
                  imageFound = true;
              } else {
                  image.classList.add('hidden');
              }
          });

          if (!imageFound) {
              productImages[0].classList.remove('hidden');
          }
      }

      function updateVariantPrice(variantId) {
          // Hide all variant prices
          document.querySelectorAll('.variant-price').forEach(price => {
              price.classList.add('hidden');
          });
          
          // Show the selected variant's price
          const selectedPrice = document.querySelector(`.variant-price[data-variant-id="${variantId}"]`);
          if (selectedPrice) {
              selectedPrice.classList.remove('hidden');
          }
      }

      variantRadios.forEach(radio => {
          radio.addEventListener('change', (event) => {
              const variantId = event.target.dataset.variantId;
              updateGalleryImage(variantId);
              updateVariantPrice(variantId);
          });
      });

      const initialSelectedRadio = document.querySelector('.product-variants-variant-values-radio:checked');
      if (initialSelectedRadio) {
          const variantId = initialSelectedRadio.dataset.variantId;
          updateGalleryImage(variantId);
          updateVariantPrice(variantId);
      } else {
          productImages[0].classList.remove('hidden');
      }
  }

  initializeProductGallery();

  $(".accordion-header").click(function () {
      $(this).toggleClass("show");
  });
});

// Handle Turbolinks cache
document.addEventListener("turbolinks:before-cache", function() {
  // Destroy all slick instances before page is cached
  if ($('.hero-slider').length) $('.hero-slider').slick('unslick');
  if ($('.fresh-slider').length) $('.fresh-slider').slick('unslick');
  if ($('.products-slider').length) $('.products-slider').slick('unslick');
});
